
.home_v1{
    align-items: center;
    display: flex;
    font-family: 'Lexend', sans-serif;
    flex-direction: column;
    justify-content: space-around;
    user-select: none;
}

.home_v1_time{
    font-size: 40px;
    text-align: right;
    padding-right: 78px;
    width: 100%;
}

.home_v1_time p{
    display: inline-block;
    font-weight: 600;
    width: 220px;
    white-space: nowrap;
}

.home_v1 h1{
    font-size: 240px;
    font-weight: 600;
    line-height: 220px;
    letter-spacing: -8px;
    margin-bottom: -48px;
}

.home_v1_arrow{
    text-align: right;
    padding-right: 108px;
    width: 100%;
}

.home_v1_arrow img{
    cursor: pointer;
    transition: transform 0.25s ease-in-out;
}

.home_v1_arrow img:hover{
    transform: translateY(6px);
}

.home_v1_arrow img:active{
    transform: translateY(10px);
}


/* Responsiveness */

@media only screen and (max-width: 1180px) {
    .home_v1_time{
        font-size: 36px;
    }

    .home_v1_time p{
        width: 200px;
    }

    .home_v1 h1{
        font-size: 210px;
        line-height: 195px;
    }
}


@media only screen and (max-width: 1080px) {
    .home_v1_time p{
        width: 190px;
    }

    .home_v1 h1{
        font-size: 190px;
        line-height: 170px;
    }
}


@media only screen and (max-width: 980px) {
    .home_v1{
        justify-content: space-between;
        padding: 30px 0;
    }

    .home_v1_time{
        padding-right: 58px;
    }

    .home_v1 h1{
        font-size: 180px;
        line-height: 160px;
    }

    .home_v1_arrow{
        padding-right: 74px;
    }
}


@media only screen and (max-width: 780px) {
    .home_v1_time{
        font-size: 32px;
    }

    .home_v1_time p{
        width: 165px;
    }

    .home_v1 h1{
        font-size: 140px;
        line-height: 120px;
    }

    .home_v1_arrow{
        padding-right: 58px;
    }
}


@media only screen and (max-width: 620px) {
    .home_v1{
        padding: 18px 0;
    }

    .home_v1_time{
        font-size: 22px;
    }

    .home_v1_time p{
        width: 110px;
    }

    .home_v1 h1{
        font-size: 120px;
        line-height: 110px;
        transform: rotate(-90deg);
        margin-bottom: 0;
    }

    .home_v1_arrow{
        text-align: center;
        padding-right: 0;
    }

    .home_v1_arrow img{
        height: 90px;
    }
}
