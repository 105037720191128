@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  transition: all 0.25s ease-in-out;
}

section{
  height: 100vh;
  width: calc(100% - 95px);
}

.App{
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}


/* Responsiveness */

@media only screen and (max-width: 980px) {
  section{
    width: calc(100% - 85px);
  }
}


@media only screen and (max-width: 780px) {
  section{
    width: calc(100% - 75px);
  }
}


@media only screen and (max-width: 620px) {
  section{
      height: calc(100vh - 70px);
      width: 100%;
  }
}
