
.topnav{
    align-items: center;
    border-bottom: 2px solid black;
    background-color: white;
    display: flex;
    font-family: 'Lexend', sans-serif;
    height: 70px;
    justify-content: space-between;
    padding: 0 42px;
    position: sticky;
    top: 0;
    transition: all 0.25s ease-in-outs;
    z-index: 10;
}

.topnav h1{
    font-size: 18px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.6px;
    padding-bottom: 2px;
}

.topnav img{
    cursor: pointer;
    height: 54%;
}

.topnav_cross{
    display: none;
}

.topnav_menu{
    align-items: center;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 28px;
    height: calc(100vh - 70px);
    justify-content: center;
    list-style: none;
    row-gap: 74px;
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    transform: translateY(-100vh);
    transition: all 0.25s ease-in-out;
    z-index: -1;
}

.topnav_menu li{
    cursor: pointer;
}

@media only screen and (min-width: 620px) {
    .topnav{
        display: none;
    }
}
  

/* Responsiveness */

@media only screen and (max-width: 420px) {
    .topnav{
        padding: 0 32px;
    }
}
