
.sidenav{
    align-items: flex-end;
    background-color: white;
    border-left: 3px solid black;
    display: flex;
    font-family: 'Lexend', sans-serif;
    font-weight: 600;
    justify-content: center;
    padding-bottom: 140px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 95px;
    z-index: 10;
}

.sidenav_home{
    cursor: pointer;
    position: absolute;
    top: 32px;
    left: 27px;
    width: 38px;
}

.sidenav h1{
    cursor: pointer;
    font-size: 40px;
    transform: rotate(-90deg);
    text-decoration: underline;
    margin-right: 8px;
    white-space: nowrap;
}

.sidenav .active{
    color: #FF7628;
}


/* Responsiveness */

@media only screen and (max-width: 980px) {
    .sidenav{
        width: 85px;
    }

    .sidenav h1{
        font-size: 36px;
    }

    .sidenav_home{
        width: 34px;
        left: 24px;
    }
}


@media only screen and (max-width: 780px) {
    .sidenav{
        width: 75px;
    }

    .sidenav h1{
        font-size: 32px;
    }

    .sidenav_home{
        width: 32px;
        left: 20px;
    }
}


@media only screen and (max-width: 620px) {
    .sidenav{
        display: none;
    }
}
