
.contact_v1{
    align-items: center;
    display: flex;
    font-family: 'Lexend', sans-serif;
    flex-direction: column;
    justify-content: space-evenly;
    user-select: none;
}

.contact_v1 h1{
    font-size: 44px;
    font-weight: 600;
    max-width: 960px;
    width: 75%;
}

.contact_v1 form{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 880px;
    width: 72%;
}

.contact_v1 input{
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.03em;
    padding: 14px 2.5px;
    outline: none;
    width: 100%;
}

.contact_v1 input::placeholder{
    color: #C3C3C3;
}

.input_container{
    column-gap: 48px;
    display: flex;
}

.input_container input{
    flex: 1;
}

.contact_v1 button{
    align-items: center;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    column-gap: 14px;
    display: flex;
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    font-size: 28px;
    padding: 8px 18px;
    margin: 28px auto 0;
}

.contact_v1 button img{
    height: 62%;
}

.contact_v1 button:hover{
    background-color: #505050;
}

.contact_v1 p{
    color: #9948FF;
    cursor: default;
    letter-spacing: -0.04em;
    margin-top: -22px;
    text-align: center;
    opacity: 0;
    width: 78%;
    transition: opacity 0.4s ease-in-out;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Responsiveness */

@media only screen and (max-width: 1080px) {
    .contact_v1 h1{
        font-size: 32px;
    }

    .contact_v1 input{
        font-size: 20px;
        letter-spacing: -0.02em;
        padding: 12px 2px;
    }

    .input_container{
        column-gap: 38px;
    }

    .contact_v1 button{
        font-size: 24px;
    }
}


@media only screen and (max-width: 780px) {
    .contact_v1 h1{
        font-size: 24px;
    }

    .contact_v1 input{
        font-size: 16px;
        letter-spacing: -0.01em;
        padding: 9px 1px;
    }

    .input_container{
        column-gap: 28px;
    }

    .contact_v1 button{
        font-size: 18px;
        column-gap: 10px;
    }

    .contact_v1 button img{
        height: 55%;
    }

    .contact_v1 p{
        font-size: 14px;
    }
}


@media only screen and (max-width: 420px) {
    .contact_v1 h1{
        font-size: 22px;
        width: 80%;
    }

    .contact_v1 form{
        width: 80%;
    }

    .contact_v1 input{
        font-size: 15px;
        letter-spacing: 0;
        padding: 8px 1px;
    }

    .input_container{
        column-gap: 24px;
    }
}