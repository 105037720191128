.info_v1{
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.info_v1_container{
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 54px;
    user-select: none;
}

.info_v1_container h1{
    font-size: 110px;
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    margin-right: -298px;
    padding-bottom: 28px;
    z-index: 1;
}

.info_v1_container h1 span{
    color: #FF7628;
    font-weight: 700;
}

.info_v1_container img{
    width: 470px;
}

.marquee {
    align-items: center;
    background-color: #FFD344;
    border: 1px solid black;
    border-right: 0;
    display: flex;
    font-family: 'Lexend', sans-serif;
    font-size: 30px;
    font-weight: 600;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    letter-spacing: -1px;
    max-width: 100%;
    overflow-x: hidden;
}
  
.track {
    animation: marquee 15s linear infinite;
    position: absolute;
    white-space: nowrap;
    will-change: transform;
}

.track span{
    font-weight: bold;
}
  
@keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}


/* Responsiveness */

@media only screen and (max-width: 1280px) {
    .info_v1_container h1{
        font-size: 102px;
        margin-right: -278px;
    }

    .info_v1_container img{
        width: 420px;
    }
}


@media only screen and (max-width: 1080px) {
    .info_v1_container h1{
        font-size: 80px;
        margin-right: -218px;
    }

    .info_v1_container img{
        width: 380px;
    }
}


@media only screen and (max-width: 920px) {
    .info_v1_container h1{
        font-size: 70px;
        margin-right: -190px;
    }

    .info_v1_container img{
        width: 340px;
    }
}


@media only screen and (max-width: 780px) {
    .info_v1_container h1{
        font-size: 60px;
        margin-right: -164px;
    }

    .info_v1_container img{
        width: 310px;
    }
}


@media only screen and (max-width: 720px) {
    .info_v1_container{
        flex-direction: column-reverse;
    }

    .info_v1_container h1{
        font-size: 60px;
        margin-right: -40px;
    }

    .info_v1_container img{
        width: 300px;
        margin-bottom: -84px;
    }
}


@media only screen and (max-width: 620px) {
    .marquee{
        font-size: 18px;
        height: 40px;
    }
}


@media only screen and (max-width: 620px) {
    .info_v1_container h1{
        font-size: 50px;
        margin-right: -18px;
    }

    .info_v1_container img{
        width: 280px;
        margin-bottom: -68px;
    }
}


@media only screen and (max-width: 400px) {
    .info_v1_container h1{
        font-size: 44px;
        margin-right: -8px;
    }

    .info_v1_container img{
        width: 260px;
        margin-bottom: -58px;
    }
}
